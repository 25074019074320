<template>
  <div>
    <b-card title="Vous pouvez rechercher les orders avec des problèmes d'infos directement ici.">
      <b-row class="mt-1">
        <b-col
          v-if="orders && orders.length"
          cols="2"
        >
          <download-csv
            :data="orders"
            name="order.csv"
          >
            <b-button class="mb-2" variant="warning">Télécharger</b-button>
          </download-csv>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="orders"
          :fields="fields"
          :busy="loading"
        >

          <template #cell(number)="data">
            <a :href="`/commande/${data.item.number}`" target="_blank">{{ data.item.number }}</a>
          </template>
          <template #cell(date_created)="data">
            <p>{{ $moment(data.item.date_created).format('DD/MM/YYYY') }}</p>
          </template>
          <template #cell(x)="data">
            <feather-icon
              icon="TrashIcon"
              size="24"
              class="trash text-warning mr-2 cursor-pointer"
              @click="removeAlert(data.item.id)"
            />
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BTable,
  VBPopover,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'

export default {
  components: {
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    // BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    'download-csv': JsonCSV,
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      dateFilter: null,
      required,
      baseOrders: [],
      orders: [],
      products: [],
      categories: [],
      order: {},
      conditionalProducts: '',
      conditionalCategories: '',
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'number', label: 'order' }, { key: 'date_created', label: 'order date' }, { key: 'content', label: 'Error' }, { key: 'x', label: '' }],
      showModal: false,
      categoryInformations: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getOrders()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getOrders() {
      try {
        this.loading = true
        const {
          orders,
        } = await this.$http.get('/admin/orders/problem-info')
        this.orders = orders
        this.baseOrders = orders
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async removeAlert(id) {
      try {
        console.log('remove', id)
        await this.$http.delete(`/admin/orders/${id}/delete-problem`)
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Problème supprimé.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        await this.getOrders()
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.orders = this.baseOrders
      } else {
        const value = this.code
        console.log(value)
        this.orders = this.baseOrders.filter(order => order.sku?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
